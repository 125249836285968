<template>
  <div class="rule">
    <div class="mask">
      <a-row type="flex" justify="center" style="width:100%">
        <a-col :xl="9" :lg="12" :md="15" :span="22">
          <RuleSearchInput></RuleSearchInput>
        </a-col>
      </a-row>
    </div>
    <a-row type="flex" justify="center" style="width: 100%;margin-top: 40px">
      <a-col :xl="18" :lg="18" :md="18" :span="22">
        <p class="search_result">{{ '搜索关于"'}} <span style="color: #0364FF"> {{ keywords }} </span>  {{'"的相关结果，共' +result.length + '条' }}</p>
        <ul class="search">
          <li v-for="(item,index) in result" :key="'result' + index">
            <a :href="screenWidth > 750 ? item.link : item.mpLink">{{item.title}}</a>
          </li>
        </ul>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import RuleSearchInput from 'views/rule/search/RuleSearchInput'
import { getRule } from 'views/rule/getRule'

export default {
  name: 'RuleSearch',
  components: { RuleSearchInput },
  data() {
    return {
      keywords: '',
      result: [],
      screenWidth: document.body.clientWidth
    }
  },
  watch: {
    '$route'(to, from) {
      this.$router.go(0)
    }
  },
  created() {
    if (this.$route.query.keywords) {
      this.keywords = this.$route.query.keywords
      this.result = getRule(this.keywords)
    } else {
      this.$router.push('/ruleCenter')
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  }
}
</script>

<style scoped lang="scss">

.mask{
  width: 100%;
  height: 240px;
  position: relative;
  background-image: url('/images/Mask_Group.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
::v-deep .ant-input-lg{
  height: 54px !important;
}
::v-deep .mask .ant-btn-lg{
  height: 54px !important;
  width: 80px;
  font-size: 25px;
}
.search{
  li {
    border-bottom:1px solid #f0f0f0;
    cursor: pointer;
    height: 60px;
    padding: 16px 0;
    text-align: left;
    font-size: 16px;
    color: #000;
  }
}
.search_result{
  font-size: 16px;
  text-align: left;
  color: #ccc;
}
</style>
