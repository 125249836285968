export const ruleCenterData = [
  {
    title: 'Vllshop店铺限定规则',
    link: '/RuleHome?activeKey=2&current=1000&ruleId=2000',
    mpLink: '/rule/ShopLimitRule'
  },
  {
    title: '交易安全规则',
    link: '/RuleHome?activeKey=2&current=1000&ruleId=2001',
    mpLink: '/rule/TransactionRule'
  },
  {
    title: '禁售规则',
    link: '/RuleHome?activeKey=2&current=1000&ruleId=2002',
    mpLink: '/rule/ShopLimitRule'
  },
  {
    title: '商品违法处罚规则',
    link: '/RuleHome?activeKey=2&current=1000&ruleId=2003',
    mpLink: '/rule/PunishRule'
  },
  {
    title: '行业管理规范',
    link: '/RuleHome?activeKey=2&current=1000&ruleId=2004',
    mpLink: '/rule/IndustryRule'
  },
  {
    title: '知识产权规则',
    link: '/RuleHome?activeKey=2&current=1000&ruleId=2005',
    mpLink: '/rule/IntellectualPropertyRule'
  },
  {
    title: 'Vllshop关于商品、订单通知及申请FAQ',
    link: '/RuleHome',
    mpLink: '/notice/ShopFAQ'
  },
  {
    title: 'Vllshop关于发布特殊品类商品信息公告',
    link: '/RuleHome?activeKey=1&current=1000',
    mpLink: '/notice/ReleaseSpecialProducts'
  },
  {
    title: 'Vllshop关于退货商品',
    link: '/RuleHome?activeKey=1&current=1002',
    mpLink: '/notice/ReturnGoods'
  },
  {
    title: 'Vllshop关于知识产权投诉公告',
    link: '/RuleHome?activeKey=1&current=1003',
    mpLink: '/notice/ReturnGoods'
  }
]

export function getRule(keywords) {
  return ruleCenterData.filter(item => {
    return item.title.indexOf(keywords) !== -1
  })
}
